import mondaySdk from "monday-sdk-js";
import { Loader } from "monday-ui-react-core";
import "monday-ui-react-core/dist/main.css";
import React, { useEffect, useState } from "react";
import "./App.css";
import Boards from "./Board";
import {
  sanitizeString,
  validateString,
  sanitizeAndValidateNumber,
} from "./securityutils";
import DOMPurify from "dompurify";

const monday = mondaySdk();

function App() {
  const [itemId, setItemId] = useState();
  const [accountId, setAccountId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [subscription, setSubscription] = useState();
  const [isGuest, setIsGuest] = useState(false);
  const [isViewer, setIsViewer] = useState(false);
  const [itemName, setItemName] = useState();
  const [boardId, setBoardId] = useState();
  const [isSubItem, setIsSubItem] = useState(false);

  monday.execute("valueCreatedForUser");
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (itemId) {
          const res = await monday.api(`query {
            items (ids: [${itemId}]) {
              name
              parent_item{
                id
              }
            }
          }`);
          const name = res.data.items[0].name;
          setItemName(name);
          if (res.data.items[0]?.parent_item?.id) {
            setIsSubItem(true);
          }
        }
      } catch (error) {}
    };

    fetchData();
  }, [itemId]);

  useEffect(() => {
    monday
      .api(
        `query {
      me {
        is_guest
        created_at
        name
        id
        is_view_only
      }
    }`
      )
      .then((res) => {
        setIsGuest(res.data.me.is_guest);
      })
      .catch((error) => {
        setIsViewer(true);
        monday.execute("notice", {
          message:
            "Error accessing monday API. This can happen if you are a viewer. As a viewer, you are unable to use this app.",
          type: "error",
          excludeCancelButton: true,
        });
      });
  }, []);

  useEffect(() => {
    monday
      .api(
        `query {
    users {
      account {
        tier
        plan {
          period
        }
      }
    }
  }`
      )
      .then((res) => {
        setAccountId(DOMPurify.sanitize(res.account_id));
      })
      .catch((error) => {});
  }, []);

  setTimeout(() => {
    setIsLoading(false);
  }, 1000);

  useEffect(() => {
    monday
      .api(
        `query {
      app_subscription {
        billing_period
        days_left
        is_trial
        plan_id
        renewal_date
      }
    }`
      )
      .then((res) => {
        setSubscription(res.data.app_subscription);
      });
  }, []);

  useEffect(() => {
    monday.listen("context", (res) => {
      setBoardId(res.data.boardId);
      setItemId(res.data.itemId);
    });
  }, []);

  return (
    <div className="container">
      {isLoading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Loader size={Loader.sizes.MEDIUM} color={Loader.colors.PRIMARY} />
        </div>
      ) : (
        <>
          {isViewer || isGuest ? (
            <>
              <div>
                <h1>Access Denied</h1>
                <div>
                  Unfortunately, your current access level does not permit the
                  use of this app. This app requires additional permissions that
                  are not available to your roles. Please contact your
                  administrator or team lead for more information on accessing
                  this application.
                </div>
              </div>
            </>
          ) : (
            <>
              {isSubItem ? (
                <div>We dont support subitem copy</div>
              ) : (
                <div className="centered-container">
                  <h2>Copy Item: {itemName}</h2>
                  <h4> to</h4>
                  <div className="board">
                    <Boards
                      boardId={boardId}
                      itemId={itemId}
                      accountId={accountId}
                      subscription={subscription}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default App;
