import mondaySdk from "monday-sdk-js";
import {
  Button,
  Checkbox,
  Icon,
  IconButton,
  Loader,
} from "monday-ui-react-core";
import "monday-ui-react-core/dist/main.css";
import React, { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import Select from "react-select";
import Mapping from "./Mapping";
import SubItemMapping from "./SubItemMapping";
import Modal from "react-modal";
import { Close, Item } from "monday-ui-react-core/icons";

function sanitizeInput(data) {
  if (typeof data === "string") {
    return data.replace(/[\\"']/g, "\\$&").replace(/\u0000/g, "\\0");
  } else if (typeof data === "number") {
    return data;
  } else if (typeof data === "object" && data !== null) {
    const cleanObject = {};
    for (const key in data) {
      cleanObject[key] = sanitizeInput(data[key]);
    }
    return cleanObject;
  }
  return data;
}

const Boards = (props) => {
  const [board, setBoard] = useState([]);
  const [group, setGroup] = useState([]);
  const [item, setItem] = useState([]);
  const [workspace, setWorkspace] = useState([]);
  const [columnValues, setColumnValues] = useState([]);
  const [selectedBoard, setSelectedBoard] = useState(null);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isDisabled, setIsDisable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [copySubitems, setCopySubitems] = useState(false);
  const [itemMapping, setItemMapping] = useState({});
  const [subItemMapping, setSubItemMapping] = useState({});
  const [hasSubItems, setHasSubItems] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const { itemId, accountId, subscription, boardId } = props;
  const [isVisible, setIsVisible] = useState(false);
  const monday = mondaySdk();

  // let subtitle;

  const customStyles = {
    content: {
      border: "none",
    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
    setIsVisible(false);
    // setSelectedGroup(null);
    setIsDisable(true);
  }

  const duplicateItem = async (boardId, itemId) => {
    const mutation = `
      mutation {
        duplicate_item (board_id: ${boardId}, item_id: ${itemId}, with_updates: true) {
          id
        }
      }
    `;
    try {
      const response = await monday.api(mutation);
      const duplicatedItemId = response.data.duplicate_item.id;
      const get_item_name_query = `query { items (ids: [${itemId}]) { name }}`;
      const ItemName = await monday.api(get_item_name_query);
      const item_name = DOMPurify.sanitize(ItemName.data.items[0].name);

      const change_item_name_query = `mutation {
        change_multiple_column_values(item_id:${duplicatedItemId}, board_id:${boardId}, column_values: "{\\"name\\": \\"${item_name}\\"}") {
          id
        }
      }`;
      await monday.api(change_item_name_query);
      return duplicatedItemId;
    } catch (error) {
      // console.log(error);
    }
  };

  const moveItemToGroup = async (selectedBoard, itemId, groupId) => {
    let columnsMappingString = "[";
    for (let key in itemMapping) {
      if (itemMapping[key]) {
        columnsMappingString += `{ source: "${key}", target: "${itemMapping[key]}" },`;
      } else {
        columnsMappingString += `{ source: "${key}", target: ${itemMapping[key]} },`;
      }
    }
    columnsMappingString = columnsMappingString.slice(0, -1);
    columnsMappingString += "]";

    let subItemsColumnsMappingString = "[";
    for (let key in subItemMapping) {
      if (subItemMapping[key]) {
        subItemsColumnsMappingString += `{ source: "${key}", target: "${subItemMapping[key]}" },`;
      } else {
        subItemsColumnsMappingString += `{ source: "${key}", target: ${subItemMapping[key]} },`;
      }
    }
    subItemsColumnsMappingString = subItemsColumnsMappingString.slice(0, -1);
    subItemsColumnsMappingString += "]";

    let mutation;

    if (hasSubItems) {
      mutation = `
      mutation {
        move_item_to_board (item_id: ${itemId}, group_id: "${groupId}" , board_id: "${selectedBoard}", columns_mapping:${columnsMappingString},subitems_columns_mapping: ${subItemsColumnsMappingString}  ) {
          id
        }
      }
    `;
    } else {
      mutation = `
      mutation {
        move_item_to_board (item_id: ${itemId}, group_id: "${groupId}" , board_id: "${selectedBoard}", columns_mapping:${columnsMappingString} ) {
          id
        }
      }
    `;
    }

    try {
      const response = await monday.api(mutation);
      const movedItemId = response.data.move_item_to_board.id;
      return movedItemId;
    } catch (error) {
      // console.log(error);
    }
  };

  async function duplicateAndMoveItem(
    boardId,
    itemId,
    selectedBoard,
    targetGroupId
  ) {
    setIsLoading(true);
    monday.execute("valueCreatedForUser");
    let copyCount = 0;
    let planExpired = false;
    let plan_id = subscription[0] ? subscription[0].plan_id : null;

    try {
      await monday.storage.getItem(accountId).then((res) => {
        if (res.data.value) {
          copyCount = sanitizeInput(res.data.value);
        }
        if (copyCount >= 10 && subscription.length === 0 || (copyCount >= 10 && plan_id ==="Free") ) {
          monday.execute("notice", {
            message:
              "You have exceeded the limits of your plan. Please upgrade your subscription to continue using Copy Item.",
            type: "error",
            timeout: 10000,
          });
          planExpired = true;
        } else if (plan_id === "standard" && copyCount >= 1000) {
          monday.execute("notice", {
            message:
              "You have exceeded the limits of your plan. Please upgrade your subscription to pro or enterprise.",
            type: "error",
            timeout: 10000,
          });
          planExpired = true;
        } else if (plan_id === "pro" && copyCount >= 5000) {
          monday.execute("notice", {
            message:
              "You have exceeded the limits of your plan. Please upgrade your subscription to unlimited for unlimited copies.",
            type: "error",
            timeout: 10000,
          });
          planExpired = true;
        }
      });

      if (!planExpired) {
        const duplicatedItemId = await duplicateItem(boardId, itemId);
        if (duplicatedItemId) {
          const movedItemId = await moveItemToGroup(
            selectedBoard,
            duplicatedItemId,
            targetGroupId
          );
          if (movedItemId) {
            monday.execute("notice", {
              message: "Copied item",
              type: "success",
              excludeCancelButton: true,
            });
          }
        }
        copyCount++;
        await monday.storage.setItem(accountId, copyCount);
      }
    } catch (error) {
      monday.execute("notice", {
        message: "Unable to copy the item!!!",
        type: "error",
        excludeCancelButton: true,
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleGroups(id) {
    try {
      const res = await monday.api(
        `query {
          boards(ids: ${id}) {
            groups {
              title
              id
            }
          }
        }`
      );
      const groups = res.data.boards[0].groups;
      setGroup(groups);
    } catch (error) {
      monday.execute("notice", {
        message: "Unable to copy the item!!!",
        type: "error",
        excludeCancelButton: true,
      });
    }
  }

  const fetchAllWorkspaces = async (page = 1, allWorkspaces = []) => {
    setIsLoading(true);
    try {
      const limit = 25;
      const response = await monday.api(`query {
      workspaces (page: ${page}, limit: ${limit}) {
        id
        name
        kind
        description
      }
    }`);

      const fetchedWorkspaces = response.data.workspaces;
      const updatedWorkspaces = allWorkspaces.concat(fetchedWorkspaces);
      if (fetchedWorkspaces.length < limit) {
        setWorkspace(updatedWorkspaces);
        setIsLoading(false);
      } else {
        fetchAllWorkspaces(page + 1, updatedWorkspaces);
      }
    } catch (error) {
      // console.error("Failed to fetch workspaces", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllWorkspaces();
  }, []);

  async function fetchSubItems() {
    setIsLoading(true);
    const subitems_query = `query {
      items (ids: ${itemId}) {
        subitems {
          id
        }
      }
    }`;

    const response = await monday.api(subitems_query);
    const subItems = response.data.items[0].subitems;
    if (subItems.length > 0) {
      setHasSubItems(true);
    } else {
      setHasSubItems(false);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchSubItems();
  }, []);

  async function handleBoard(workspace_id) {
    setIsLoading(true);
    try {
      let allBoards = [];
      let page = 1;
      const limit = 25;
      let fetchMore = true;

      while (fetchMore) {
        const response = await monday.api(`query {
          boards (workspace_ids: [${workspace_id}], page: ${page}, limit: ${limit}) {
            id
            name
            state
            type
            description
          }
        }`);
        const boards = response.data.boards.filter(
          (board) => board.type === "board"
        );
        allBoards = allBoards.concat(boards);
        fetchMore = response.data.boards.length == limit;
        page += 1;
      }

      setBoard(allBoards);
    } catch (error) {
      monday.execute("notice", {
        message: "Unable to fetch boards!",
        type: "error",
        excludeCancelButton: true,
      });
    } finally {
      setIsLoading(false);
    }
  }

  const boardOptions = board.map((b) => ({ value: b.id, label: b.name }));
  const groupOptions = group.map((gr) => ({ value: gr.id, label: gr.title }));

  return (
    <div className="dropdown-container">
      <Select
        options={workspace.map((ws) => ({ value: ws.id, label: ws.name }))}
        onChange={(selectedOption) => {
          const selectedWorkspaceId = selectedOption.value;
          setSelectedWorkspace(selectedWorkspaceId);
          handleBoard(selectedWorkspaceId);
        }}
        placeholder="Select a Workspace"
        isClearable={true}
        isSearchable={true}
      />

      {selectedWorkspace && (
        <Select
          options={boardOptions}
          onChange={(selectedOption) => {
            const selectedBoardId = selectedOption
              ? selectedOption.value
              : null;
            setSelectedBoard(selectedBoardId);
            handleGroups(selectedBoardId);
          }}
          placeholder="Select a Board"
          isClearable={true}
          isSearchable={true}
          noOptionsMessage={() => "No boards found"}
        />
      )}

      {selectedBoard && (
        <Select
          value={groupOptions.find((option) => option.value === selectedGroup)} // Set the currently selected value
          options={groupOptions} // Options formatted for react-select
          onChange={(selectedOption) => {
            const selectedGroupId = selectedOption
              ? selectedOption?.value
              : null;
            setSelectedGroup(selectedGroupId);
            setIsDisable(!selectedOption); // Disable button if no group is selected
            if (selectedGroupId) {
              openModal();
            }
          }}
          placeholder="Select a Group" // Placeholder text
          isClearable={true} // Allows users to clear the selected option
          isSearchable={true} // Enables the search functionality
          noOptionsMessage={() => "No groups found"} // Message when no options match the search
        />
      )}
      <div className="">
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          ariaHideApp={false}
        >
          <div className="modal-container">
            <div className="content">
              {!isVisible && selectedGroup && (
                <Mapping
                  originalBoardId={boardId}
                  targetBoardId={selectedBoard}
                  setItemMapping={setItemMapping}
                  hasSubItems={hasSubItems}
                  setIsVisible={setIsVisible}
                  setIsOpen={setIsOpen}
                />
              )}

              {selectedGroup && isVisible && hasSubItems && (
                <SubItemMapping
                  originalBoardId={boardId}
                  targetBoardId={selectedBoard}
                  setSubItemMapping={setSubItemMapping}
                  setIsVisible={setIsVisible}
                  setIsOpen={setIsOpen}
                />
              )}
            </div>

            <div className="close-btn">
              <IconButton
                iconType={Icon.type.SVG}
                icon={Close}
                iconSize={10}
                size="xs"
                kind={IconButton.kinds.TERTIARY}
                onClick={closeModal}
              />
            </div>
          </div>
        </Modal>
      </div>

      {/* <div className="checkbox-container">
        <Checkbox
          checked={copySubitems}
          onChange={(e) => setCopySubitems(e.target.checked)}
          label="Copy Subitems"
        >
          Copy Subitems
        </Checkbox>
      </div> */}

      <div className="button-container">
        <Button
          size={Button.sizes.SMALL}
          onClick={() => {
            duplicateAndMoveItem(boardId, itemId, selectedBoard, selectedGroup);
          }}
          disabled={isDisabled || isLoading}
        >
          {isLoading && (
            <Loader size={Loader.sizes.XS} color={Loader.colors.PRIMARY} />
          )}
          {!isLoading && "Copy Item"}
        </Button>
      </div>
    </div>
  );
};

export default Boards;
