import mondaySdk from "monday-sdk-js";
import { Button, Loader } from "monday-ui-react-core";
import React, { useEffect, useState } from "react";
import Select from "react-select";

const monday = mondaySdk();

const SubItemMapping = ({originalBoardId , targetBoardId,setSubItemMapping,setIsOpen,setIsVisible}) => {
  const [originalBoardColumns, setOriginalBoardColumns] = useState([]);
  const [targetBoardColumns, setTargetBoardColumns] = useState([]);
  const [mapping, setMapping] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchColumnNames();
  }, []);

  async function getColumnsNames(boardId) {
    const get_columns_names_query = `query {
        boards(ids: [${boardId}]) {
          items_page {
            items {
              name
              subitems {
               name
                column_values {
                  column {
                    id
                    title
                    type
                  }
                }
              }
            }
          }
        }
      }`;
    const response = await monday.api(get_columns_names_query);
    let columnNames = extractSubItemColumns(response);
    return columnNames.filter((item) => item.id !== "name" && item.type !=="formula");
  }

  function extractSubItemColumns(response) {
    let subItemColumns = [];
    const items = response.data.boards[0].items_page.items;
    if (items.length > 0) {
      for (let item of items) {
        let subItems = item.subitems;
        if (subItems.length > 0) {
          const subItem = subItems[0];
          const columnValues = subItem.column_values;
          if (columnValues.length > 0) {
            for (let columnValue of columnValues) {
              subItemColumns.push({
                id: columnValue.column.id,
                title: columnValue.column.title,
                type: columnValue.column.type,
              });
            }
            return subItemColumns;
          }
        }
      }
    }
    return subItemColumns;
  }

  async function fetchColumnNames() {
    setIsLoading(true);
    const original_board_columns = await getColumnsNames(originalBoardId);
    const target_board_columns = await getColumnsNames(targetBoardId);
    const updated_target_board_columns = target_board_columns.map((column) => ({
      ...column,
      isUsed: false,
    }));
    setTargetBoardColumns(updated_target_board_columns);
    setOriginalBoardColumns(original_board_columns);
    setIsLoading(false);
  }

  function handleSelectChange(selectedOption, originalColumn) {
    const previousSelected = mapping[originalColumn.id];
    let updatedTargetColumns;

    if (previousSelected) {
      updatedTargetColumns = targetBoardColumns.map((item) => {
        if (item.id === previousSelected) {
          item.isUsed = false;
        }
        return item;
      });
    }
    setMapping((prevState) => ({
      ...prevState,
      [originalColumn.id]: selectedOption ? selectedOption.value : null,
    }));

    updatedTargetColumns = targetBoardColumns.map((item) => {
      if (item.id === selectedOption?.value) {
        item.isUsed = true;
      }
      return item;
    });
    setTargetBoardColumns(updatedTargetColumns);
  }

  function handleNoOptions(originalColumn) {
    return `Create a column mannualy of type ${originalColumn.type} or information will be lost`;
  }

  function handleDefaultValue(originalColumn) {
    let updatedTargetColumns = targetBoardColumns;

    for (let column of updatedTargetColumns) {
      if (column.id === originalColumn?.id) {
        column.isUsed = true;
        setMapping((prevState) => ({
          ...prevState,
          [originalColumn.id]: column.id,
        }));
        setTargetBoardColumns(updatedTargetColumns);
        const option = {
          value: column.id,
          label: column.title,
        };
        return option;
      }
    }

    setMapping((prevState) => ({
      ...prevState,
      [originalColumn.id]: null,
    }));

    return null;
  }

  function handleClick() {
    monday.execute("notice", {
      message: "Mapping done!!",
      type: "success",
      excludeCancelButton: true,
    });
    setSubItemMapping(mapping);
    setIsOpen(false);
    setIsVisible(false)
  }

  return (
    <div className="table-container">
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Loader size={Loader.sizes.MEDIUM} color={Loader.colors.PRIMARY} />
        </div>
      )}
      {!isLoading && (
        <>
          <h3 className="mapping-heading">Please Map Sub-Item Columns</h3>
          <table className="data-table">
            <tbody>
              {originalBoardColumns.map((originalColumn) => (
                <tr key={originalColumn.id}>
                  <td>{originalColumn.title}</td>
                  <td>
                    <Select
                      options={targetBoardColumns
                        .filter(
                          (targetColumn) =>
                            targetColumn.type === originalColumn.type &&
                            !targetColumn.isUsed
                        )
                        .map((targetColumn) => ({
                          value: targetColumn.id,
                          label: targetColumn.title,
                        }))}
                      placeholder="Select a Column"
                      isClearable={true}
                      isSearchable={true}
                      onChange={(selectedOption) =>
                        handleSelectChange(selectedOption, originalColumn)
                      }
                      noOptionsMessage={() => handleNoOptions(originalColumn)}
                      defaultValue={() => handleDefaultValue(originalColumn)}
                      className="select"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="submit-btn">
            <Button onClick={handleClick}>Submit</Button>
          </div>
        </>
      )}
    </div>
  );
};

export default SubItemMapping;
